


























import Vue from "vue"

export default Vue.extend({
  name: "BuzzInBtn",
  props: {
    isWaiting: {
      type: Boolean,
      default: undefined
    },
    isActive: {
      type: Boolean,
      default: undefined
    },
    buzzCount: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    btnText() {
      if (this.isWaiting) return "Waiting..."
      if (this.isActive) return "BUZZ IN"
      if (this.buzzCount === 1) return this.buzzCount + "ST"
      if (this.buzzCount === 2) return this.buzzCount + "ND"
      if (this.buzzCount === 3) return this.buzzCount + "RD"
      return this.buzzCount + "TH"
    }
  }
})
