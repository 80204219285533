var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "buzz-in-btn",
        class:
          ((_obj = {
            "buzz-in-btn--active": _vm.isActive,
          }),
          (_obj["buzz-in-btn--buzzed-" + _vm.buzzCount] =
            _vm.buzzCount > 0 && _vm.buzzCount < 3),
          (_obj["buzz-in-btn--buzzed"] = _vm.buzzCount > 2),
          _obj),
      },
      _vm.$listeners
    ),
    [
      _vm.isActive || _vm.buzzCount
        ? _c("SvgIcon", {
            staticClass: "buzz-in-btn__icon mr-auto",
            attrs: { name: "buzz-left" },
          })
        : _vm._e(),
      _c("span", [_vm._v(" " + _vm._s(_vm.btnText) + " ")]),
      _vm.isActive || _vm.buzzCount
        ? _c("SvgIcon", {
            staticClass: "buzz-in-btn__icon ml-auto",
            attrs: { name: "buzz-right" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }