import useStore from "@/use/useStore"
import { db } from "@/firebase"

export default function useZoomableImageStateRef() {
  const { store } = useStore()
  const roomId: string | undefined = store.getters.game?.id
  const missionId: string | undefined = store.getters.getCurrentMission?.id
  const ref = db.auxiliary().ref(`_room/${roomId}/charades/${missionId}`)

  return { ref }
}
