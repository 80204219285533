import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import isVideo from "is-video"
import useZoomableImage from "@/components/ZoomableImage/useZoomableImage"

import Sfx from "@shared/Sfx"
import { AssetTypes } from "@/components/GroupTeams/Common/AssetMapper.vue"
export default Vue.extend({
  name: "BuzzInAction",
  props: {
    disabled: {
      type: Boolean,
      default: undefined
    }
  },
  setup() {
    const { pause } = useZoomableImage()
    return { pause }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    assetType() {
      const { audio, youtube, photo } = this.$store.getters.getCurrentMission
      return (
        (photo && AssetTypes.Photo) ||
        (audio && AssetTypes.Audio) ||
        (isVideo(youtube) && AssetTypes.Ourvideo) ||
        (youtube && AssetTypes.Video)
      )
    },
    slotData() {
      return {
        onBuzzIn: this.onBuzzIn
      }
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateGameSoundEffect"]),
    ...mapActions("group", [
      "updateMissionAudioState",
      "updateMissionMediaState"
    ]),
    ...mapActions(["addBuzz"]),
    async onBuzzIn() {
      if (this.disabled) return
      const { Ourvideo, Video, Audio } = AssetTypes
      const { assetType } = this
      try {
        const promises = [
          this.addBuzz({
            missionID: this.$store.getters.currentMission,
            userID: this.user.id,
            teamID: this.user.teamID
          })
        ]

        if (!this.$store.getters.getCurrentMission?.disableActionSfx)
          promises.push(this.updateGameSoundEffect(Sfx.SUBMIT))

        if (this.$store.getters.getCurrentMission?.zoomable) 
          promises.push(this.pause())
        else if (assetType === Ourvideo || assetType === Video)
          promises.push(this.updateMissionMediaState({ paused: true }))
        else if (assetType === Audio)
          promises.push(this.updateMissionAudioState({ paused: true }))

        await Promise.all(promises)
      } catch (e) {
        console.error(e)
      }
    }
  },
  render() {
    return this.$scopedSlots.default(this.slotData)
  }
})
