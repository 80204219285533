import useZoomableImageStateRef from "./useZoomableImageStateRef"
import type { TimelineLite } from "gsap/TweenMax"

let tl: TimelineLite

export default function useZoomableImage(timeline?: TimelineLite) {
  const { ref: stateRef } = useZoomableImageStateRef()

  if (timeline != null) tl = timeline

  function pause() {
    tl.pause()
    return stateRef.transaction(value => {
      if (value?.playing === false) return
      return {
        playing: false,
        progress: tl.time()
      }
    })
  }

  return { tl, pause }
}
